import React, { useState } from 'react';
import { 
  AppBar, 
  Toolbar, 
  Typography, 
  Button, 
  Box, 
  IconButton,
  useScrollTrigger,
  Container,
  Menu,
  MenuItem,
  useTheme,
  useMediaQuery,
  alpha,
  Tooltip
} from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import GitHubIcon from '@mui/icons-material/GitHub';
import MenuIcon from '@mui/icons-material/Menu';

interface HeaderProps {
  toggleColorMode: () => void;
  mode: 'light' | 'dark';
}

const Header: React.FC<HeaderProps> = ({ toggleColorMode, mode }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  const navigationLinks = [
    { title: 'Home', path: '/' },
    // { title: 'Features', path: '/features' },
    { title: 'About', path: '/about' },
    { title: 'Contact', path: '/contact' },
  ];

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const isActive = (path: string) => {
    return location.pathname === path;
  };

  const getBackgroundColor = () => {
    if (trigger) {
      return mode === 'dark' 
        ? alpha(theme.palette.background.default, 0.9)
        : alpha(theme.palette.background.default, 0.9);
    }
    return 'transparent';
  };

  const getTextColor = () => {
    if (trigger) {
      return theme.palette.text.primary;
    }
    return mode === 'dark' ? '#fff' : '#000';
  };

  return (
    <AppBar 
      position="sticky" 
      elevation={trigger ? 4 : 0}
      sx={{
        backgroundColor: getBackgroundColor(),
        backdropFilter: 'blur(10px)',
        borderBottom: trigger 
          ? 'none' 
          : `1px solid ${alpha(theme.palette.divider, 0.1)}`,
        transition: 'all 0.3s ease-in-out',
        color: getTextColor()
      }}
    >
      <Container maxWidth="lg">
        <Toolbar sx={{ px: { xs: 0 }, position: 'relative', justifyContent: 'space-between' }}>
          {/* Logo */}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <img 
              src={mode === 'dark' ? '/vinyl-icon-1-transparent-no-needle.png' : '/vinyl-icon-1-transparent-black-no-needle.png'} 
              alt="Soundcred Logo" 
              style={{ width: 35, height: 35, marginRight: 8, marginBottom: 2 }}
            />
            <Typography 
              variant="h6" 
              component={Link} 
              to="/"
              sx={{ 
                textDecoration: 'none', 
                color: 'inherit',
                fontWeight: 700,
                letterSpacing: 1,
                '&:hover': {
                  opacity: 0.8
                }
              }}
            >
              SOUNDCRED
            </Typography>
          </Box>

          {/* Desktop Navigation - Centered */}
          {!isMobile && (
            <Box 
              sx={{ 
                position: 'absolute',
                left: '50%',
                transform: 'translateX(-50%)',
                display: 'flex',
                gap: 2
              }}
            >
              {navigationLinks.map((link) => (
                <Button
                  key={link.path}
                  component={Link}
                  to={link.path}
                  sx={{
                    color: 'inherit',
                    position: 'relative',
                    opacity: isActive(link.path) ? 1 : 0.7,
                    '&::after': {
                      content: '""',
                      position: 'absolute',
                      width: isActive(link.path) ? '100%' : '0%',
                      height: '2px',
                      bottom: 0,
                      left: '0',
                      backgroundColor: 'primary.main',
                      transition: 'width 0.2s ease-in-out'
                    },
                    '&:hover': {
                      opacity: 1,
                      backgroundColor: 'transparent'
                    },
                    '&:hover::after': {
                      width: '100%'
                    }
                  }}
                >
                  {link.title}
                </Button>
              ))}
            </Box>
          )}

          {/* Right Side: Mobile Menu or Theme Toggle */}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {isMobile && (
              <IconButton
                aria-label="menu"
                onClick={handleMenuOpen}
                sx={{ 
                  color: 'inherit',
                  mr: 1
                }}
              >
                <MenuIcon />
              </IconButton>
            )}
            <Tooltip title="Link Coming Soon">
              <IconButton 
                sx={{ 
                  color: 'inherit',
                  mr: 1,
                  '&:hover': {
                    transform: 'scale(1.1)',
                    backgroundColor: 'transparent'
                  }
                }}
              >
                <GitHubIcon />
              </IconButton>
            </Tooltip>
            <IconButton 
              onClick={toggleColorMode} 
              sx={{ 
                color: 'inherit',
                transition: 'transform 0.2s ease-in-out',
                '&:hover': {
                  transform: 'rotate(90deg)',
                  backgroundColor: 'transparent'
                }
              }}
            >
              {mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
            </IconButton>
          </Box>

          {/* Mobile Menu */}
          {isMobile && (
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiMenuItem-root': {
                    minWidth: 180,
                  }
                },
              }}
            >
              {navigationLinks.map((link) => (
                <MenuItem 
                  key={link.path}
                  onClick={handleMenuClose}
                  component={Link}
                  to={link.path}
                  selected={isActive(link.path)}
                  sx={{
                    '&.Mui-selected': {
                      backgroundColor: alpha(theme.palette.primary.main, 0.1),
                      '&:hover': {
                        backgroundColor: alpha(theme.palette.primary.main, 0.2),
                      }
                    }
                  }}
                >
                  {link.title}
                </MenuItem>
              ))}
            </Menu>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;